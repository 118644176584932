import Swal from "sweetalert2";

export const alerts = {
  dataSendConfirm: function ({ title, message, buttonTextYes, buttonTextNo } = {}) {
    return Swal.fire({
      title: title || "¿Está seguro?",
      text: message || "Sus datos serán enviados",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#bd0909",
      cancelButtonColor: "#515151",
      confirmButtonText: buttonTextYes || "Si enviar",
      cancelButtonText: buttonTextNo || "No, cancelar",
    });
  },

  dataSending: function ({ title, message } = {}) {
    Swal.fire({
      title: title || "Enviando",
      text: message || "Los datos se están enviando y procesando",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  },

  dataSendError: function ({ title, message, buttonText } = {}) {
    Swal.close();
    Swal.fire({
      title: title || "Error",
      html: message || "Error enviando los datos",
      icon: "error",
      timerProgressBar: true,
      confirmButtonColor: "#bd0909",
    });
  },

  dataSendSuccess: function ({ title, message, buttonText } = {}) {
    Swal.close();
    Swal.fire({
      title: title || "Completado",
      text: message || "Haz enviado tus datos exitosamente, pronto te contactaremos",
      icon: "success",
      confirmButtonText: buttonText || "Aceptar",
      timerProgressBar: true,
      confirmButtonColor: "#bd0909",
    });
  },
  dataSwalClose: function () {
    Swal.close();
  },
};

export default alerts;
