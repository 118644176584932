<template>
  <div class="verifyCode">
    <div class="verifyCode__card">
      <h1 class="verifyCode__title">Models 1A</h1>
      <form id="form-valid-code">
        <div class="verifyCode__form">
          <label class="verifyCode__subtitle" for="codeVerify">Código de verificación</label>
          <input class="verifyCode__input" name="codeVerify" @input.prevent="chageValue" placeholder="Ingrese código" />
        </div>
        <button class="verifyCode__button" @click.prevent="sendChangePassword">Enviar</button>
      </form>
    </div>
  </div>
</template>

<script>
import api from "@/api/index";
import alerts from "../../assets/js/swalAlerts";
export default {
  name: "verifyCodePass",
  data() {
    return {
      name: this.$route.query.name || "",
      email: this.$route.query.email || "",
    };
  },
  methods: {
    chageValue: function () {
      const el = document.querySelector(".verifyCode__input");
      this.$validate.validateInput(el, { type: "code", length: 6 });
    },
    sendChangePassword: async function () {
      const fullPath = this.$route.fullPath;
      const _host = process.env.VUE_APP_API_HOST;
      const valid = _host.slice(-1) === "/" ? `user${fullPath}` : `/user${fullPath}`;
      const URL = `${process.env.VUE_APP_API_HOST}${valid}`;
      this.chageValue();
      const error = document.querySelector(".data-error");
      if (error) return;
      const _data = new FormData(document.getElementById(`form-valid-code`));
      alerts.dataSending({
        title: "Enviando código",
        text: "Espere un momento mientras se envía el código",
      });
      const data = await api.post(URL, _data);
      alerts.dataSwalClose();
      if (data.data.state) {
        if (this.name && this.email) {
          localStorage.setItem("statepass", data.data.code);
          this.$router.push({
            path: `/update_pass?email=${this.email}&name=${this.name}`,
            query: {
              name: this.name,
              email: this.email,
            },
          });
        }
      } else {
        alerts.dataSendError({
          title: "Error",
          message: "Código inválido",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.verifyCode {
  padding: $mpadding;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  &__card {
    background-color: $alto;
    padding: $mpadding * 2;
    border-radius: 12px;
    color: $primary_color;
    box-shadow: $dshadow;
  }
  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
    font-family: $third_font;
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__subtitle {
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: $black;
    font-weight: bold;
    justify-content: center;
  }
  &__input {
    width: 100%;
    height: 40px;
    padding: 0 $mpadding;
    margin-bottom: $mpadding/5;
    font-size: 20px;
    border-radius: 5px;
    text-align: center;
    text-overflow: ellipsis;
    border: none;
    &::placeholder {
      color: $alto;
    }
  }
  &__button {
    margin-top: $mpadding;
    width: 100%;
    height: 38px;
    font-size: 100%;
    color: $white;
    background: $primary_color;
    border-radius: 7px;
    border: none;
    &:hover {
      background-color: $primary_color_hover;
      cursor: pointer;
    }
  }
}
</style>
